import { useScript } from '@bluheadless/ui-logic/src/hooks/use-script/useScript'
import { Root } from '@/components/ui/organisms/foursixty/foursixty.styled'
import { cx } from '@emotion/css'

const Foursixty = ({ script_url, script_data, style, ...props }) => {
	useScript({
		src: script_url,
		attributes: { ...script_data },
		elementIdToAppend: 'foursixty-container',
	})

	return (
		<Root className={cx('Foursixty-generic')} {...props}>
			<div id="foursixty-container"></div>
			<div dangerouslySetInnerHTML={{ __html: style }}></div>
		</Root>
	)
}

export default Foursixty
